import { render, staticRenderFns } from "./Screen.vue?vue&type=template&id=5fa86f9d"
var script = {}
import style0 from "./Screen.vue?vue&type=style&index=0&id=5fa86f9d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports