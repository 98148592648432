<template>
	<div
		class="landing-activity"
		:class="{
			'step-2': landingStep === 2,
			'landing-activity_pilates': AREA.PILATES_FEMALE && landingStep === 2,
			'landing-activity_chair-yoga': IS_FEMALE_ACTIVITY_CHAIR_YOGA_COMP_FUNNEL_EXP,
		}"
	>
		<div class="container-fluid" v-if="landingStep === 1">
			<div class="landing-activity__header">
				<img class="landing-activity__header-logo" :src="logoSrc" alt="" />
			</div>
			<transition appear name="slide-up">
				<div>
					<div
						class="landing-activity__title"
						:class="{
							'landing-activity__title_chair-yoga': IS_FEMALE_ACTIVITY_CHAIR_YOGA_COMP_FUNNEL_EXP,
						}"
						v-html="content.title"
					></div>
					<div
						class="landing-activity__subtitle yogacomp-subtitle"
						v-if="IS_FEMALE_ACTIVITY_SOMATIC_YOGA_COMP_FUNNEL_EXP"
					>
						<div class="yogacomp-subtitle__text">
							<b>release emotions, reduce tension, and lose weight for your age</b>
						</div>
						<div class="yogacomp-subtitle__quiz">1-minute quiz</div>
					</div>
					<div
						class="landing-activity__subtitle yogacomp-chair-yoga-subtitle"
						v-else-if="IS_FEMALE_ACTIVITY_CHAIR_YOGA_COMP_FUNNEL_EXP"
					>
						<div class="yogacomp-chair-yoga-subtitle__text">
							<b>FOR YOUR AGE GROUP</b>
						</div>
						<div class="yogacomp-chair-yoga-subtitle__quiz">1-minute quiz</div>
					</div>
					<div class="landing-activity__subtitle" v-else>
						<i18n path="female_activity.pages.landing.step_1.subtitle">
							<template #age>
								<b>{{ $t('female_activity.pages.landing.step_1.age') }}</b>
							</template>
						</i18n>
					</div>
				</div>
			</transition>
			<transition appear name="slide-up-1">
				<div>
					<div
						:class="{ 'landing-activity__grid_small': IS_WELCOME_COOKIE_OPTIMIZATION_EXP }"
						class="landing-activity__grid"
					>
						<div
							class="landing-activity__age-item"
							v-for="ageRange in ageRanges"
							:key="ageRange.value"
							:class="{ active: ageRange.value === activeValue }"
							@click="onClick(ageRange)"
						>
							<img class="landing-activity__age-image" :src="ageRange.img" alt="" />
							<div class="landing-activity__age-item-bottom">
								<div class="landing-activity__age-item-text">{{ ageRange.label }}</div>
								<div class="landing-activity__age-item-icon">
									<img height="24" src="@/assets/landing-age-selection/chevron_right.svg" alt="" />
								</div>
							</div>
						</div>
					</div>

					<div
						v-if="AREA.YOGA_FEMALE || AREA.SOMATIC_FEMALE || AREA.WALKING_FEMALE"
						class="landing-activity__other-ages"
					>
						<button
							@click="onClick({ value: 18, analyticValue: '18-39' })"
							class="landing-activity__age-18 btn"
							type="button"
						>
							<span>{{ content.picker.im_young }}</span>
							<i class="icon-arrow-right"></i>
						</button>
					</div>
				</div>
			</transition>
		</div>
		<div v-else class="landing-activity__step-2-container">
			<div class="container-fluid landing-activity__step-2-wrapper">
				<div class="landing-activity__women-header">
					<div class="landing-activity__women-header-left">
						<img src="@/assets/landing-activity-female/title-left.png" alt="" />
					</div>
					<div class="landing-activity__women-header-middle">
						<div class="landing-activity__women-header-title">
							{{ $t('female_activity.pages.landing.step_2.title') }}
						</div>
						<div class="landing-activity__women-header-subtitle">
							{{
								$t('female_activity.pages.landing.step_2.subtitle', {
									age: ageDecade,
								})
							}}
						</div>
					</div>
					<div class="landing-activity__women-header-right">
						<img src="@/assets/landing-activity-female/title-right.png" alt="" />
					</div>
				</div>
				<div class="landing-activity__header-text">{{ step2Subtitle }}</div>
				<img class="landing-activity__women-image" :src="step2ImageSrc" alt="women" />
				<div class="landing-activity__step-2-spacer"></div>
			</div>
			<div class="landing-activity__button-container">
				<div class="container-fluid">
					<button class="btn btn-block btn-primary" @click="onStep2Click">
						{{ $t('female_activity.pages.landing.step_2.cta') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import OpenTelemetry from '@/modules/OpenTelemetry'
import { mapGetters, mapMutations } from 'vuex'

import { ANALYTIC_AGE_TO_AGE_RANGE_MAP } from '@/helpers/constants'
import { getAgeDecade } from '@/helpers/utils'
import { mergeTranslationsWithFallback } from '@/i18n'

import ageImg18 from '@/assets/landing-activity-female/age-18.webp'
import ageImg30 from '@/assets/landing-activity-female/age-30.webp'
import ageImg40 from '@/assets/landing-activity-female/age-40.webp'
import ageImg50 from '@/assets/landing-activity-female/age-50.webp'
import ageImg60 from '@/assets/landing-activity-female/age-60.webp'
import ageMaleImg18 from '@/assets/landing-activity-male/age-18.png'
import ageMaleImg30 from '@/assets/landing-activity-male/age-30.png'
import ageMaleImg40 from '@/assets/landing-activity-male/age-40.png'
import ageMaleImg50 from '@/assets/landing-activity-male/age-50.png'
import walking40 from '@/assets/landing-activity-female/walking-40.webp'
import walking50 from '@/assets/landing-activity-female/walking-50.webp'
import walking60 from '@/assets/landing-activity-female/walking-60.webp'
import walking70 from '@/assets/landing-activity-female/walking-70.webp'
import yoga40 from '@/assets/landing-activity-female/yoga-40.webp'
import yoga50 from '@/assets/landing-activity-female/yoga-50.webp'
import yoga60 from '@/assets/landing-activity-female/yoga-60.webp'
import yoga70 from '@/assets/landing-activity-female/yoga-70.webp'
import chairYoga40 from '@/assets/landing-activity-female/chair-yoga-40.webp'
import chairYoga50 from '@/assets/landing-activity-female/chair-yoga-50.webp'
import chairYoga60 from '@/assets/landing-activity-female/chair-yoga-60.webp'
import chairYoga70 from '@/assets/landing-activity-female/chair-yoga-70.webp'
import grayHairedWoman from '@/assets/landing-activity-female/gray-haired-woman.webp'

export default {
	name: 'LandingActivity',
	data() {
		return {
			clicked: false,
			activeValue: null,
			landingStep: 1,
		}
	},

	computed: {
		...mapGetters({
			propertyValue: 'survey/getPropertyValue',
			appTheme: 'getAppTheme',
		}),

		ageRanges() {
			if (this.AREA.ACTIVITY_MALE) {
				return [
					{
						value: 18,
						analyticValue: '18-29',
						label: this.content.picker.age_18_29,
						img: ageMaleImg18,
					},
					{
						value: 30,
						analyticValue: '30-39',
						label: this.content.picker.age_30_39,
						img: ageMaleImg30,
					},
					{
						value: 40,
						analyticValue: '40-49',
						label: this.content.picker.age_40_49,
						img: ageMaleImg40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: this.content.picker.age_50,
						img: ageMaleImg50,
					},
				]
			} else if (this.IS_FEMALE_ACTIVITY_SOMATIC_YOGA_COMP_FUNNEL_EXP) {
				return [
					{
						value: 40,
						analyticValue: '40-49',
						label: 'Age: 35-44',
						img: yoga40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: 'Age: 45-54',
						img: yoga50,
					},
					{
						value: 60,
						analyticValue: '60-69',
						label: 'Age: 55-64',
						img: yoga60,
					},
					{
						value: 70,
						analyticValue: '70+',
						label: 'Age: 65+',
						img: yoga70,
					},
				]
			} else if (this.IS_FEMALE_ACTIVITY_CHAIR_YOGA_COMP_FUNNEL_EXP) {
				return [
					{
						value: 40,
						analyticValue: '40-49',
						label: 'Age: 35-44',
						img: chairYoga40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: 'Age: 45-54',
						img: chairYoga50,
					},
					{
						value: 60,
						analyticValue: '60-69',
						label: 'Age: 55-64',
						img: chairYoga60,
					},
					{
						value: 70,
						analyticValue: '70+',
						label: 'Age: 65+',
						img: chairYoga70,
					},
				]
			}
			if (this.AREA.YOGA_FEMALE || this.AREA.SOMATIC_FEMALE || this.AREA.WALKING_FEMALE) {
				return [
					{
						value: 40,
						analyticValue: '40-49',
						label: this.content.picker.age_40_49,
						img: this.AREA.WALKING_FEMALE ? walking40 : ageImg40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: this.content.picker.age_50,
						img: this.AREA.WALKING_FEMALE ? walking50 : ageImg50,
					},
					{
						value: 60,
						analyticValue: '60-69',
						label: this.content.picker.age_60_69,
						img: this.AREA.WALKING_FEMALE ? walking60 : ageImg60,
					},
					{
						value: 70,
						analyticValue: '70+',
						label: this.content.picker.age_70_80,
						img: this.AREA.WALKING_FEMALE ? walking70 : grayHairedWoman,
					},
				]
			} else {
				return [
					{
						value: 18,
						analyticValue: '18-29',
						label: this.content.picker.age_18_29,
						img: ageImg18,
					},
					{
						value: 30,
						analyticValue: '30-39',
						label: this.content.picker.age_30_39,
						img: ageImg30,
					},
					{
						value: 40,
						analyticValue: '40-49',
						label: this.content.picker.age_40_49,
						img: ageImg40,
					},
					{
						value: 50,
						analyticValue: '50-59',
						label: this.content.picker.age_50,
						img: ageImg50,
					},
				]
			}
		},

		nextRoute() {
			if (this.IS_AGE_SELECTION_LANDING_TYPE) {
				if (this.AREA.BAU_FEMALE) {
					return 'female-survey'
				} else if (this.AREA.BAU_MALE) {
					return 'male-survey'
				} else {
					return this.MAIN_FUNNEL_ROUTE_NAME
				}
			}
			if (this.AREA.PILATES_FEMALE) {
				return 'female-activity-pilates'
			}
			if (this.AREA.YOGA_FEMALE) {
				return 'female-activity-chair-yoga'
			}
			if (this.AREA.SOMATIC_FEMALE) {
				return 'female-activity-somatic-yoga'
			}
			if (this.AREA.WALKING_FEMALE) {
				return 'female-activity-walking'
			}
			if (this.AREA.ACTIVITY_MALE) {
				return 'male-activity-onboarding'
			}
			return 'female-activity-onboarding'
		},

		ageDecade() {
			return this.$t(`pages.additional-question-1.age.${getAgeDecade(this.propertyValue('age', true))}`)
		},

		title() {
			if (this.IS_FEMALE_ACTIVITY_SOMATIC_YOGA_COMP_FUNNEL_EXP) {
				return 'SOMATIC YOGA'
			}
			if (this.IS_FEMALE_ACTIVITY_CHAIR_YOGA_COMP_FUNNEL_EXP) {
				return 'CHAIR YOGA'
			}
			if (this.AREA.SOMATIC_FEMALE) {
				return this.$t('female_somatic.pages.landing.step_1.title')
			}
			if (this.AREA.WALKING_FEMALE) {
				return this.$t('female_walking.pages.landing.step_1.title')
			}
			if (this.AREA.PILATES_FEMALE) {
				return this.$t('female_pilates.landing.step_1.title')
			}
			if (this.AREA.YOGA_FEMALE) {
				return this.$t('female_yoga.pages.landing.step_2.header')
			}
			return this.$t('female_activity.pages.landing.step_1.title')
		},

		step2Subtitle() {
			return ''
		},

		step2ImageSrc() {
			if (this.AREA.PILATES_FEMALE) {
				return require('@/assets/landing-activity-female/step-2-pilates.webp')
			}
			return ''
		},

		logoSrc() {
			if (this.appTheme === 'navy') {
				return require('@/assets/logos/logo-white-inline.svg')
			}
			return require('@/assets/logos/logo-gray-inline.svg')
		},

		content() {
			let im_young = this.$t('female_yoga.pages.landing.im_young')
			let age_18_29 = this.$t('female_activity.pages.landing.step_1.options.18_29')
			let age_30_39 = this.$t('female_activity.pages.landing.step_1.options.30_39')
			let age_40_49 = this.$t('female_activity.pages.landing.step_1.options.40_49')
			let age_50 = this.$t('female_activity.pages.landing.step_1.options.50')
			let age_60_69 = this.$t('female_yoga.pages.landing.age_range.60_69')
			let age_70_80 = this.$t('female_yoga.pages.landing.age_range.70_80')

			if (this.AREA.YOGA_FEMALE || this.AREA.SOMATIC_FEMALE || this.AREA.WALKING_FEMALE) {
				age_40_49 = this.$t('female_yoga.pages.landing.age_range.40_49')
				age_50 = this.$t('female_yoga.pages.landing.age_range.50_59')
			}

			return mergeTranslationsWithFallback({
				type: 'age_selection',
				key: this.getQueryParam('lpid'),
				fallback: {
					title: this.title,
					picker: {
						im_young,
						age_18_29,
						age_30_39,
						age_40_49,
						age_50,
						age_60_69,
						age_70_80,
					},
				},
			})
		},
	},

	methods: {
		...mapMutations({
			updateProperty: 'survey/updateProperty',
			updateSurveyModel: 'survey/updateSurveyModel',
		}),

		onClick(e) {
			if (this.clicked) {
				return
			}
			this.clicked = true
			this.activeValue = e.value
			setTimeout(() => {
				if (this.AREA.PILATES_FEMALE) {
					this.updateProperty({ property: 'age', value: e.value, isData: true })
					this.$absmartly.attributes({ age_range: ANALYTIC_AGE_TO_AGE_RANGE_MAP[e.value] })

					this.eventsPromise = this.$analytic.logEvent('OnboardingAgeRangeScreenDone', { data: e.analyticValue })
					this.eventsPromise.then(() => {
						// DEV-15541 smartly a/a sequential
						this.$absmartly.treatment('sequential_aa_low_volumes')
						this.$analytic.setUserProperties({ 'Age Range': e.analyticValue })
						this.$analytic.logEvent('ScreenView', { title: 'ONBOARDING_WOMEN_YOUR_AGE_INFO_SCREEN' })
					})

					this.goToWelcome(e)
				} else {
					this.$analytic.logEvent('OnboardingWelcomeScreenDone').then(() => {
						this.$analytic.setUserProperties({ 'Age Range': e.analyticValue })
						this.$analytic.logEvent('OnboardingAgeRangeScreenDone', { data: e.analyticValue })
						// DEV-15541 smartly a/a sequential
						this.$absmartly.treatment('sequential_aa_low_volumes')
					})
					if (this.IS_AGE_SELECTION_LANDING_TYPE) {
						this.updateSurveyModel({
							...this.$store.state.survey.model,
							age: [e.value.toString()],
						})
					}
					this.updateProperty({ property: 'age', value: e.value, isData: true })
					this.goToWelcome(e)
				}
			}, 300)
		},

		goToWelcome() {
			OpenTelemetry.startSpan('vue-landing-to-onboarding', {
				attributes: {
					'span.type': 'vue-landing-to-onboarding',
				},
			})

			this.$router.push({ name: this.nextRoute })
			this.activeValue = null
			this.clicked = false
		},

		onStep2Click() {
			this.eventsPromise.then(() => {
				this.$analytic.logEvent('OnboardingWomenYourAgeInfoScreenDone')
			})
			this.goToWelcome()
		},
	},

	mounted() {
		OpenTelemetry.endSpan('vue-landing-mounted')
	},
}
</script>

<style scoped lang="scss">
.landing-activity {
	padding-top: 0.8rem;

	&.step-2 {
		min-height: 100vh;
		min-height: 100dvh;
		display: flex;
		flex-direction: column;
		padding-top: 2rem;

		&.landing-activity_pilates {
			min-height: auto;
		}
	}

	&_chair-yoga {
		.landing-activity__title {
			margin-bottom: 0;
		}

		.landing-activity__age-item-top {
			background: var(--Fill-Base);
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1.5rem;
		height: 21px;

		&-logo {
			height: 21px;
			width: 76px;
		}
	}

	&__title {
		font-size: var(--size-32);
		font-weight: 700;
		letter-spacing: -0.04rem;
		text-align: center;
		line-height: var(--size-38);
		margin-bottom: 1rem;
		text-transform: uppercase;

		&_chair-yoga {
			margin-bottom: 0;
		}
	}

	&__subtitle {
		font-size: var(--size-18);
		font-style: normal;
		font-weight: 400;
		line-height: var(--size-22);
		margin-bottom: 1rem;
		text-align: center;

		&_xl {
			font-size: var(--size-28);
			line-height: var(--size-32);
			font-weight: 700;
		}
	}

	.yogacomp-subtitle {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__text {
			margin: 0 var(--size-24);
			font-size: var(--size-24);
			font-weight: 600;
		}

		&__quiz {
			font-size: var(--size-18);
			font-weight: 500;
			margin-top: var(--size-12);
		}
	}

	.yogacomp-chair-yoga-subtitle {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__text {
			font-size: var(--size-20);
			font-weight: 600;
		}

		&__quiz {
			font-size: var(--size-18);
			font-weight: 500;
			color: var(--Text-Primary-40);
			margin-top: var(--size-12);
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 0.75rem;
		align-items: end;

		&_small {
			gap: 0.5rem 0.75rem;
			.landing-activity__age-image {
				max-height: 140px;
			}
		}
	}

	&__age-item {
		position: relative;
		overflow: hidden;
		border-radius: 0 0 1rem 1rem;
		cursor: pointer;

		&.active {
			animation: click 0.2s ease-in-out;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -23%;
			border-radius: 100%;
			width: 100%;
			height: 100%;
			background: linear-gradient(127deg, rgba(100, 114, 255, 0.2) 19.21%, rgba(211, 135, 255, 0.2) 88.68%);
			z-index: -1;
		}
	}

	&__age-item-bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0.7rem;
		background: linear-gradient(90deg, #6471ff 6.87%, #d286ff 90.24%);
		border-radius: 0 0 var(--size-16) var(--size-16);
		font-weight: 600;
		line-height: 1.25;
		font-size: 1rem;
		color: var(--Text-White-100);
		z-index: 2;

		svg {
			fill: var(--Fill-Accent-100);
		}
	}

	&__age-item-top {
		background: linear-gradient(127deg, rgba(100, 114, 255, 0.2) 19.21%, rgba(211, 135, 255, 0.2) 88.68%);
		border-radius: 47.6% 50.5% 0 0;
		z-index: 0;
	}

	&__age-image {
		max-height: 155px;
		width: 100%;
		height: 9.7rem; // ~155px - for performance purposes
		object-fit: contain;
		pointer-events: none;

		&_small {
			max-height: 140px;
		}
	}

	&__age-18 {
		font-size: var(--size-16);
		font-weight: 600;
		padding: 0;
		margin-bottom: var(--size-20);
	}

	&__other-ages {
		text-align: center;
		font-weight: 600;
		margin-top: 1.5rem;

		i {
			font-size: 0.75rem;
			margin-left: 0.4rem;
		}
	}

	&__women-header {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__women-header-left,
	&__women-header-right {
		flex-shrink: 0;
		width: 2.25rem;
	}

	&__women-header-middle {
		padding: 0 1.5rem;
		text-align: center;
	}

	&__women-header-title {
		font-size: 2rem;
		font-weight: 800;
		line-height: 1;
		text-transform: uppercase;
		@supports (background-clip: text) {
			background: linear-gradient(90deg, #6472ff 0%, #d387ff 100%);
			background-clip: text;
			color: transparent;
		}
		margin-bottom: 0.5rem;
	}

	&__women-header-subtitle {
		font-size: 1.125rem;
		font-weight: 700;
		line-height: normal;
	}

	&__header-text {
		margin: 1rem auto 1rem auto;
		font-size: 1.125rem;
		font-weight: 500;
		line-height: 1.33;
		text-align: center;
		width: calc(100% - 5rem);
	}

	&__women-image {
		width: calc(100% + 1.25rem * 2);
		margin-left: -1.25rem;
		max-width: none;
	}

	&__step-2-container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	&__step-2-spacer {
		flex-grow: 1;
	}

	&__step-2-wrapper {
		flex-grow: 1;
	}

	&__button-container {
		background: linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255) 80%, rgba(255, 255, 255, 0));
		position: sticky;
		bottom: 0;
		padding-top: 1.5rem;
		padding-bottom: 1rem;
		.btn {
			margin-top: 0;
		}
	}
}

[data-theme='navy'] {
	.landing-activity__age-item-bottom {
		background: linear-gradient(90deg, #586bff 6.87%, #8997ff 90.24%);
	}
}

@keyframes click {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0px, 3px);
	}
	100% {
		transform: translate(0, 0);
	}
}

@keyframes slide-up {
	from {
		opacity: 0;
		transform: translateY(40px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.slide-up-enter-active {
	transition: all 0.4s ease-in-out;
}

.slide-up-1-enter-active {
	transition: all 0.3s 0.2s ease-in-out;
}

.slide-up-enter,
.slide-up-1-enter {
	opacity: 0;
	transform: translateY(100px);
}
</style>
