<template>
	<Screen class="landing">
		<template #header>
			<BaseHeader class="mt-2" theme="gray" centered />
		</template>
		<template #content>
			<div class="container-fluid">
				<div
					:class="{ 'mx-3': AREA.PILATES_FEMALE, landing__title_lg: IS_BELLY_TYPES_CREATIVES_WELCOME }"
					class="landing__title"
				>
					{{ content.title }}
				</div>
				<div
					:class="{ landing__subtitle_lg: IS_BELLY_TYPES_CREATIVES_WELCOME }"
					class="landing__subtitle"
					v-if="content.subtitle"
					v-html="content.subtitle"
				/>
				<div class="landing__animation" :class="{ landing__animation_img: IS_BELLY_TYPES_CREATIVES_WELCOME }">
					<img v-if="IS_BELLY_TYPES_CREATIVES_WELCOME" src="@/assets/landing/belly-types.png" alt="" height="173" />
					<div v-else id="animationContainer"></div>
				</div>
				<div class="landing__loader">
					<div class="landing__loader_label">{{ content.loader.label }}</div>
					<ProcessingBar
						:duration="processingBarDuration"
						:label="$t('pages.landing.loading_the_test')"
						:switch-inverse-text-color="false"
						theme="male-primary-contrasted"
						:compact="true"
						@onCompleted="goToWelcome"
					/>
					<div class="landing__loader_note">{{ content.loader.note }}</div>
				</div>

				<div class="landing__footer">
					<p>
						{{ $t('landing_male.app_description') }}
					</p>
					<p>
						{{ $t('landing_male.results_disclaimer') }}
					</p>
				</div>
			</div>
		</template>
	</Screen>
</template>

<script>
import { mapMutations } from 'vuex'
import femaleLoaderAnimation from '@/data/lottie-animations/femaleLoader.json'
import OpenTelemetry from '@/modules/OpenTelemetry'

import Screen from '@/components/Screen.vue'
import BaseHeader from '@/components/BaseHeader.vue'
import ProcessingBar from '@/components/ProcessingBar.vue'
import { mergeTranslationsWithFallback } from '@/i18n'

export default {
	name: 'LandingFemale',

	components: {
		Screen,
		BaseHeader,
		ProcessingBar,
	},

	computed: {
		processingBarDuration() {
			return 3500
		},

		nextRoute() {
			if (this.AREA.PILATES_FEMALE) {
				return 'a-input-activity'
			}
			return 'female-survey'
		},

		title() {
			if (this.AREA.PILATES_FEMALE) {
				return (
					this.$t('female_pilates.landing.step_1.title') +
					' ' +
					this.$t('female_activity.pages.landing.step_1.subtitle', {
						age: this.$t('female_activity.pages.landing.step_1.age'),
					}).toLowerCase()
				)
			}
			if (this.IS_BELLY_TYPES_CREATIVES_WELCOME) {
				return this.$t('female_funnel.pages.landing.title_weight_loss')
			}
			return this.$t('pages.landing.male_funnel.title')
		},

		subtitle() {
			if (this.IS_BELLY_TYPES_CREATIVES_WELCOME) {
				return this.$t('female_funnel.pages.landing.subtitle_weight_loss')
			}
			return null
		},

		content() {
			return mergeTranslationsWithFallback({
				type: 'female_funnel',
				key: this.getQueryParam('lpid'),
				fallback: {
					title: this.title,
					subtitle: this.subtitle,
					loader: {
						label: this.$t('landing_male.loader.label'),
						note: this.$t('landing_male.loader.note'),
					},
				},
			})
		},
	},

	methods: {
		...mapMutations({
			updateProperty: 'survey/updateProperty',
		}),

		goToWelcome() {
			const goal = 'lose_weight'

			this.updateProperty({ property: 'goal', value: [goal], isData: true })

			this.$analytic.logEvent('OnboardingWelcomeScreenDone', {
				data: goal,
			})

			OpenTelemetry.startSpan('vue-landing-to-onboarding', {
				attributes: { 'span.type': 'vue-landing-to-onboarding' },
			})

			this.$router.push({ name: this.nextRoute })
		},

		runAnimation() {
			const animation = lottie.loadAnimation({
				container: document.getElementById('animationContainer'),
				renderer: 'svg',
				loop: false,
				autoplay: true,
				animationData: JSON.parse(JSON.stringify(femaleLoaderAnimation)),
				rendererSettings: {
					className: 'lottie-animation',
				},
			})

			animation.setSpeed(1)
		},
	},

	mounted() {
		if (!this.IS_BELLY_TYPES_CREATIVES_WELCOME) {
			this.runAnimation()
		}

		OpenTelemetry.endSpan('vue-landing-mounted')
	},
}
</script>

<style lang="scss" scoped>
.landing {
	background-color: #f3f3f3;

	&__title {
		font-size: 1.125rem;
		font-weight: 700;
		text-align: center;
		margin-top: 1rem;
		line-height: 1.3;

		&_lg {
			margin-top: var(--size-8);
			font-size: var(--size-28);
			letter-spacing: -0.56px;
			line-height: normal;
		}
	}

	&__animation {
		margin-top: 1.6rem;
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;

		background-image: url('@/assets/landing/lottie-background.png'); // For faster LCP detection

		&_img {
			max-width: 100%;
			margin-top: var(--size-40);
			margin-bottom: var(--size-40);
			text-align: center;
		}
	}

	&__loader {
		text-align: center;

		&_label {
			font-size: 1rem;
			font-weight: 600;
			margin-bottom: 0.8rem;
		}

		&_note {
			margin-top: 0.8rem;
			color: var(--text-color-primary);
		}
	}

	&__footer {
		margin-top: 2rem;
		margin-bottom: 1.5rem;
		color: #32365366;
		font-size: 0.875rem;
		line-height: 1.25rem;

		p {
			margin-bottom: 0;
			margin-top: 1.5rem;
		}
	}

	&__subtitle {
		text-align: center;
		margin-top: var(--size-8);

		&_lg {
			margin-top: var(--size-16);
			font-size: var(--size-18);
			line-height: var(--size-22);
		}
	}

	&__forbes {
		display: flex;
		justify-content: center;
		align-items: baseline;
		gap: var(--size-12);
		margin-top: var(--size-24);
		margin-bottom: var(--size-16);
		font-size: var(--size-16);
		line-height: var(--size-20);
	}
}

:deep(.lottie-animation) {
	max-height: 360px;
}
</style>
